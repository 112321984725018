<template>
  <div style="position: relative">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
      <el-breadcrumb-item>{{
        fun.getLanguageText($store.state.language, "设备管理")
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{
        fun.getLanguageText($store.state.language, "配方模板")
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{
        fun.getLanguageText($store.state.language, "模板编辑")
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName">
      <el-tab-pane
        :label="fun.getLanguageText($store.state.language, '基本设定')"
        name="system"
      >
        <el-form
          :inline="true"
          label-position="left"
          label-width="200px"
          status-icon
        >
          <el-form-item
            :label="
              fun.getLanguageText($store.state.language, '有效信号认定时长大于')
            "
          >
            <el-input v-model.number="system.effectSignalTime"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "秒(默认两秒)")
            }}</span>
          </el-form-item>
          <el-form-item
            label=""
            :label="
              fun.getLanguageText($store.state.language, '泵开启后冲水时间')
            "
          >
            <el-input
              v-model.number="system.pumpFlushTime"
              autocomplete="off"
            ></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "秒")
            }}</span>
          </el-form-item>
          <el-form-item
            label=""
            :label="
              fun.getLanguageText($store.state.language, '药剂间冲水时间')
            "
          >
            <el-input
              v-model.number="system.reagentFlushTime"
              autocomplete="off"
            ></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "秒(默认两秒)")
            }}</span>
          </el-form-item>
          <el-form-item
            label=""
            :label="
              fun.getLanguageText($store.state.language, '泵体最长执行时间')
            "
          >
            <el-input v-model.number="system.pumpMaxTime"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "秒(超过时间关停)")
            }}</span>
          </el-form-item>
          <el-form-item
            label=""
            :label="fun.getLanguageText($store.state.language, '气冲时间')"
          >
            <el-input v-model.number="system.airFlushTime"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "秒")
            }}</span>
          </el-form-item>
          <el-form-item
            label=""
            :label="fun.getLanguageText($store.state.language, '最终冲水时间')"
          >
            <el-input v-model.number="system.finalFlushTime"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "秒")
            }}</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane
        class="set1"
        :label="fun.getLanguageText($store.state.language, '配方设定1')"
        name="b"
      >
        <div class="title">
          {{ fun.getLanguageText($store.state.language, "配方参数设定") }}
        </div>
        <el-table
          :data="tableData"
          :max-height="$store.state.clientHeight"
          class="mall"
          style="width: 100%"
        >
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙')"
            prop="date"
            width="200"
          >
            <template slot-scope="scope">
              <el-input
                :value="
                  fun.getLanguageText($store.state.language, scope.row.name)
                "
                v-if="scope.row.name === '配方名称\\药剂名称'"
                disabled
                placeholder=""
              ></el-input>
              <el-input
                v-model="scope.row.name"
                v-else
                placeholder=""
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙1')"
          >
            <el-table-column prop="1">
              <template slot-scope="scope">
                <el-input v-model="scope.row[1]" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="2">
              <template slot-scope="scope">
                <el-input v-model="scope.row[2]" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="3">
              <template slot-scope="scope">
                <el-input v-model="scope.row[3]" placeholder=""></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙2')"
          >
            <el-table-column prop="4">
              <template slot-scope="scope">
                <el-input v-model="scope.row[4]" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="5">
              <template slot-scope="scope">
                <el-input v-model="scope.row[5]" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="6">
              <template slot-scope="scope">
                <el-input v-model="scope.row[6]" placeholder=""></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙3')"
          >
            <el-table-column prop="7">
              <template slot-scope="scope">
                <el-input v-model="scope.row[7]" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="8">
              <template slot-scope="scope">
                <el-input v-model="scope.row[8]" placeholder=""></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙4')"
          >
            <el-table-column prop="9">
              <template slot-scope="scope">
                <el-input v-model="scope.row[9]" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="10">
              <template slot-scope="scope">
                <el-input v-model="scope.row[10]" placeholder=""></el-input>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane> -->

      <el-tab-pane
        class="set1"
        :label="fun.getLanguageText($store.state.language, '配方设定1')"
        name="b"
      >
        <div class="title">
          {{ fun.getLanguageText($store.state.language, "配方参数设定") }}
        </div>
        <el-table
          :data="tableData"
          :max-height="$store.state.clientHeight"
          class="mall"
          style="width: 100%"
        >
          <el-table-column label="" width="110">
            <template slot-scope="scope">
              <span>{{
                scope.$index === 0
                  ? fun.getLanguageText($store.state.language, "配方编号")
                  : scope.$index
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙')"
            prop="date"
            width="200"
          >
            <el-table-column prop="name" width="200">
              <template slot-scope="scope">
                <el-input
                  v-if="scope.row.name !== '配方名称\\药剂名称'"
                  v-model="scope.row.name"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
                <span v-else>{{
                  fun.getLanguageText(
                    $store.state.language,
                    "配方名称\\药剂名称"
                  )
                }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙1')"
          >
            <el-table-column prop="1">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[1]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="2">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[2]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="3">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[3]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙2')"
          >
            <el-table-column prop="4">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[4]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="5">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[5]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="6">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[6]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙3')"
          >
            <el-table-column prop="7">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[7]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="8">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[8]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="fun.getLanguageText($store.state.language, '洗衣龙4')"
          >
            <el-table-column prop="9">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[9]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="10">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row[10]"
                  :placeholder="
                    fun.getLanguageText($store.state.language, '请输入内容')
                  "
                ></el-input>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane
        :label="fun.getLanguageText($store.state.language, '配方设定2')"
        name="second"
      >
        <el-form
          :inline="true"
          class="second"
          label-position="left"
          label-width="100px"
          status-icon
        >
          <el-form-item
            label=""
            prop="username"
            style="width: 100%; margin-bottom: 0;"
          >
            <span
              style="width: 100px; height: 0; display: inline-block;"
            ></span>
            <span
              style="width: 135px; display: inline-block; text-align: center;"
              >{{ fun.getLanguageText($store.state.language, "泵1") }}</span
            >
            <span
              style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;"
              >{{ fun.getLanguageText($store.state.language, "泵2") }}</span
            >
            <span
              style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;"
              >{{ fun.getLanguageText($store.state.language, "泵3") }}</span
            >
            <span
              style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;"
              >{{ fun.getLanguageText($store.state.language, "泵4") }}</span
            >
          </el-form-item>
          <el-form-item
            :label="fun.getLanguageText($store.state.language, '气冲时间(秒)')"
            label-width="120px"
            prop="username"
            style="width: 100%;"
          >
            <el-input
              v-model="rinse.pump_1_air"
              style="width: 135px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_2_air"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_3_air"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_4_air"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="fun.getLanguageText($store.state.language, '水冲时间(秒)')"
            label-width="120px"
            prop="username"
            style="width: 100%;"
          >
            <el-input
              v-model="rinse.pump_1_water"
              style="width: 135px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_2_water"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_3_water"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_4_water"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="
              fun.getLanguageText($store.state.language, '最终水冲时间(秒)')
            "
            label-width="120px"
            prop="username"
            style="width: 100%;"
          >
            <el-input
              v-model="rinse.pump_1_final"
              style="width: 135px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_2_final"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_3_final"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_4_final"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
       -->
      <el-tab-pane
        :label="fun.getLanguageText($store.state.language, '配方设定2')"
        name="second"
      >
        <el-form
          :inline="true"
          class="second"
          label-position="left"
          label-width="100px"
          status-icon
        >
          <el-form-item
            label=""
            prop="username"
            style="width: 100%; margin-bottom: 0;"
          >
            <span
              style="width: 300px; height: 0; display: inline-block;"
            ></span>
            <span
              style="width: 135px; display: inline-block; text-align: center;"
              >{{ fun.getLanguageText($store.state.language, "泵") }}1</span
            >
            <span
              style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;"
              >{{ fun.getLanguageText($store.state.language, "泵") }}2</span
            >
            <span
              style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;"
              >{{ fun.getLanguageText($store.state.language, "泵") }}3</span
            >
            <span
              style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;"
              >{{ fun.getLanguageText($store.state.language, "泵") }}4</span
            >
          </el-form-item>
          <el-form-item
            :label="fun.getLanguageText($store.state.language, '气冲时间(秒)')"
            label-width="300px"
            prop="username"
            style="width: 100%;"
          >
            <el-input
              v-model="rinse.pump_1_air"
              style="width: 135px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_2_air"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_3_air"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_4_air"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="fun.getLanguageText($store.state.language, '水冲时间(秒)')"
            label-width="300px"
            prop="username"
            style="width: 100%;"
          >
            <el-input
              v-model="rinse.pump_1_water"
              style="width: 135px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_2_water"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_3_water"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_4_water"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="
              fun.getLanguageText($store.state.language, '最终水冲时间(秒)')
            "
            label-width="300px"
            prop="username"
            style="width: 100%;"
          >
            <el-input
              v-model="rinse.pump_1_final"
              style="width: 135px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_2_final"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_3_final"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
            <el-input
              v-model="rinse.pump_4_final"
              style="width: 135px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane
        class="price"
        :label="fun.getLanguageText($store.state.language, '化料单价设定')"
        name="third"
      >
        <el-form
          :inline="true"
          label-position="left"
          label-width="200px"
          status-icon
        >
          <el-form-item :label="'1-' + first_feed.medicamentName_1">
            <el-input v-model="price.medicament_1_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'6-' + first_feed.medicamentName_6"
            prop="username"
          >
            <el-input v-model="price.medicament_6_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'2-' + first_feed.medicamentName_2"
            prop="username"
          >
            <el-input v-model="price.medicament_2_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'7-' + first_feed.medicamentName_7"
            prop="username"
          >
            <el-input v-model="price.medicament_7_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'3-' + first_feed.medicamentName_3"
            prop="username"
          >
            <el-input v-model="price.medicament_3_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'8-' + first_feed.medicamentName_8"
            prop="username"
          >
            <el-input v-model="price.medicament_8_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'4-' + first_feed.medicamentName_4"
            prop="username"
          >
            <el-input v-model="price.medicament_4_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'9-' + first_feed.medicamentName_9"
            prop="username"
          >
            <el-input v-model="price.medicament_9_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'5-' + first_feed.medicamentName_5"
            prop="username"
          >
            <el-input v-model="price.medicament_5_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
          <el-form-item
            :label="'10-' + first_feed.medicamentName_10"
            prop="username"
          >
            <el-input v-model="price.medicament_10_price"></el-input>
            <span class="el-input-none">RMB/Liter</span>
          </el-form-item>
        </el-form>
      </el-tab-pane> -->

      <el-tab-pane
        class="price"
        :label="fun.getLanguageText($store.state.language, '化料单价设定')"
        name="third"
      >
        <el-form
          :inline="true"
          label-position="left"
          label-width="200px"
          status-icon
        >
          <el-form-item :label="'1-' + first_feed.medicamentName_1">
            <el-input v-model="price.medicament_1_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'6-' + first_feed.medicamentName_6"
            prop="username"
          >
            <el-input v-model="price.medicament_6_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'2-' + first_feed.medicamentName_2"
            prop="username"
          >
            <el-input v-model="price.medicament_2_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'7-' + first_feed.medicamentName_7"
            prop="username"
          >
            <el-input v-model="price.medicament_7_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'3-' + first_feed.medicamentName_3"
            prop="username"
          >
            <el-input v-model="price.medicament_3_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'8-' + first_feed.medicamentName_8"
            prop="username"
          >
            <el-input v-model="price.medicament_8_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'4-' + first_feed.medicamentName_4"
            prop="username"
          >
            <el-input v-model="price.medicament_4_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'9-' + first_feed.medicamentName_9"
            prop="username"
          >
            <el-input v-model="price.medicament_9_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'5-' + first_feed.medicamentName_5"
            prop="username"
          >
            <el-input v-model="price.medicament_5_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
          <el-form-item
            :label="'10-' + first_feed.medicamentName_10"
            prop="username"
          >
            <el-input v-model="price.medicament_10_price"></el-input>
            <span class="el-input-none">{{
              fun.getLanguageText($store.state.language, "RMB/Liter")
            }}</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane
        class="aline"
        :label="fun.getLanguageText($store.state.language, '药剂系数修正')"
        name="reagent"
      >
        <el-form
          :inline="true"
          label-position="left"
          label-width="200px"
          status-icon
        >
          <el-form-item label="">
            <span class="el-input-none" style="margin-left: 220px;">A</span>
            <span class="el-input-none" style="margin-left: 150px;">B</span>
          </el-form-item>
          <el-form-item label="">
            <span class="el-input-none" style="margin-left: 220px;">A</span>
            <span class="el-input-none" style="margin-left: 150px;">B</span>
          </el-form-item>
          <el-form-item :label="'1-' + first_feed.medicamentName_1">
            <el-input
              v-model="reagent.medicament_1_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_1_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item :label="'6-' + first_feed.medicamentName_6">
            <el-input
              v-model="reagent.medicament_6_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_6_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item :label="'2-' + first_feed.medicamentName_2">
            <el-input
              v-model="reagent.medicament_2_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_2_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="'7-' + first_feed.medicamentName_7"
            prop="username"
          >
            <el-input
              v-model="reagent.medicament_7_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_7_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="'3-' + first_feed.medicamentName_3"
            prop="username"
          >
            <el-input
              v-model="reagent.medicament_3_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_3_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="'8-' + first_feed.medicamentName_8"
            prop="username"
          >
            <el-input
              v-model="reagent.medicament_8_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_8_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="'4-' + first_feed.medicamentName_4"
            prop="username"
          >
            <el-input
              v-model="reagent.medicament_4_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_4_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="'9-' + first_feed.medicamentName_9"
            prop="username"
          >
            <el-input
              v-model="reagent.medicament_9_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_9_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="'5-' + first_feed.medicamentName_5"
            prop="username"
          >
            <el-input
              v-model="reagent.medicament_5_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_5_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="'10-' + first_feed.medicamentName_10"
            prop="username"
          >
            <el-input
              v-model="reagent.medicament_10_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_10_B"
              style="width: 100px; margin-left: 20px;"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane> -->
      <el-tab-pane
        class="aline"
        :label="fun.getLanguageText($store.state.language, '药剂系数修正')"
        name="reagent"
      >
        <el-form
          :inline="true"
          label-position="left"
          label-width="150px"
          status-icon
        >
          <el-form-item label="">
            {{ fun.getLanguageText($store.state.language, "编号") }}
            <span class="el-input-none" style="margin-left: 100px;">A</span>
            <span class="el-input-none" style="margin-left: 100px;">X</span>
            <span class="el-input-none" style="margin-left: 100px;">B</span>
          </el-form-item>
          <el-form-item label="">
            {{ fun.getLanguageText($store.state.language, "编号") }}
            <span class="el-input-none" style="margin-left: 100px;">A</span>
            <span class="el-input-none" style="margin-left: 100px;">X</span>
            <span class="el-input-none" style="margin-left: 100px;">B</span>
          </el-form-item>
          <el-form-item :label="'1--' + first_feed.medicamentName_1">
            <el-input
              v-model="reagent.medicament_1_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_1_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_1_B"
              style="width: 100px;"
            ></el-input>
          </el-form-item>
          <el-form-item :label="'6--' + first_feed.medicamentName_6">
            <el-input
              v-model="reagent.medicament_6_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_6_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_6_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'2--' + first_feed.medicamentName_2">
            <el-input
              v-model="reagent.medicament_2_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_2_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_2_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'7--' + first_feed.medicamentName_7">
            <el-input
              v-model="reagent.medicament_7_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_7_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_7_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'3--' + first_feed.medicamentName_3">
            <el-input
              v-model="reagent.medicament_3_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_3_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_3_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'8--' + first_feed.medicamentName_8">
            <el-input
              v-model="reagent.medicament_8_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_8_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_8_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'4--' + first_feed.medicamentName_4">
            <el-input
              v-model="reagent.medicament_4_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_4_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_4_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'9--' + first_feed.medicamentName_9">
            <el-input
              v-model="reagent.medicament_9_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_9_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_9_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'5--' + first_feed.medicamentName_5">
            <el-input
              v-model="reagent.medicament_5_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_5_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_5_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
          <el-form-item :label="'10--' + first_feed.medicamentName_10">
            <el-input
              v-model="reagent.medicament_10_A"
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">X</span>
            <el-input
              v-model="reagent.medicament_10_X"
              disabled
              style="width: 100px;"
            ></el-input>
            <span class="el-input-none">➕</span>
            <el-input
              v-model="reagent.medicament_10_B"
              style="width: 100px; "
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-button
      style="position: absolute;right: 0;top: 0"
      type="primary"
      @click="editSave"
    >
      {{ fun.getLanguageText($store.state.language, "提交") }}
    </el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio_system: 1,
      tableData: [],
      value: "",
      activeName: "system",
      formData: {
        username: "",
        password: "",
        email: "",
        type: "",
        corporateName: "",
      },
      rinse: {
        pump_3_final: "0",
        pump_1_air: "0",
        pump_4_air: "0",
        pump_1_water: "0",
        pump_2_water: "0",
        pump_3_water: "0",
        pump_4_water: "0",
        pump_2_air: "0",
        pump_3_air: "0",
        pump_1_final: "0",
        pump_2_final: "0",
        pump_4_final: "0",
      },
      system: {
        effectSignalTime: "",
        pumpMaxTime: "",
        pumpFlushTime: "",
        reagentFlushTime: "",
        finalFlushTime: "",
        airFlushTime: "",
      },
      first_feed: {
        medicamentName_1: "",
        medicamentName_2: "",
        medicamentName_3: "",
        medicamentName_4: "",
        medicamentName_5: "",
        medicamentName_6: "",
        medicamentName_7: "",
        medicamentName_8: "",
        medicamentName_9: "",
        medicamentName_10: "",
        medicamentTime_1: "",
        medicamentTime_2: "",
        medicamentTime_3: "",
        medicamentTime_4: "",
        medicamentTime_5: "",
        medicamentTime_6: "",
        medicamentTime_7: "",
        medicamentTime_8: "",
        medicamentTime_9: "",
        medicamentTime_10: "",
      },
      reagent: {
        medicament_7_A: "0.0",
        medicament_10_A: "0.0",
        medicament_1_A: "1.10",
        medicament_2_A: "0.0",
        medicament_3_A: "0.0",
        medicament_3_B: "0.0",
        medicament_10_B: "0.0",
        medicament_1_B: "0.0",
        medicament_2_B: "3.30",
        medicament_4_B: "0.0",
        medicament_7_B: "0.0",
        medicament_8_B: "0.0",
        medicament_4_A: "0.0",
        medicament_5_A: "0.0",
        medicament_8_A: "0.0",
        medicament_9_B: "0.0",
        medicament_6_B: "0.0",
        medicament_6_A: "0.0",
        medicament_9_A: "0.0",
        medicament_5_B: "0.0",
      },
      formulation: {
        medicamentName_9_volume_9: "0",
        medicamentName_9_volume_11: "0",
        medicamentName_2_volume_7: "0",
        medicamentName_2_volume_10: "0",
        medicamentName_4_volume_10: "0",
        medicamentName_6_volume_7: "0",
        medicamentName_9_volume_6: "0",
        medicamentName_7_volume_10: "0",
        medicamentName_8_volume_7: "0",
        medicamentName_10_volume_5: "0",
        medicamentName_2_volume_5: "0",
        medicamentName_4_volume_2: "0",
        medicamentName_4_volume_7: "0",
        medicamentName_5_volume_3: "0",
        medicamentName_7_volume_8: "0",
        medicamentName_10_volume_7: "0",
        medicamentName_10_volume_10: "0",
        medicamentName_10_volume_14: "0",
        medicamentName_9_volume_7: "0",
        medicamentName_9_volume_13: "0",
        medicamentName_1_volume_14: "0",
        medicamentName_2: "",
        medicamentName_6: "",
        medicamentName_6_volume_3: "0",
        medicamentName_6_volume_15: "0",
        medicamentName_8_volume_15: "0",
        medicamentName_9_volume_10: "0",
        medicamentName_10_volume_3: "0",
        medicamentName_4_volume_11: "0",
        medicamentName_5_volume_15: "0",
        medicamentName_6_volume_5: "0",
        medicamentName_6_volume_6: "0",
        medicamentName_7_volume_5: "0",
        medicamentName_10_volume_9: "0",
        medicamentName_7_volume_6: "0",
        medicamentName_8_volume_6: "0",
        medicamentName_8_volume_14: "0",
        medicamentName_1_volume_6: "0",
        medicamentName_2_volume_8: "0",
        medicamentName_3_volume_14: "0",
        medicamentName_4_volume_14: "0",
        medicamentName_7_volume_1: "0",
        medicamentName_2_volume_4: "0",
        medicamentName_9: "",
        medicamentName_2_volume_13: "0",
        medicamentName_3_volume_3: "0",
        medicamentName_9_volume_8: "0",
        medicamentName_9_volume_14: "0",
        medicamentName_9_volume_1: "0",
        medicamentName_1_volume_5: "0",
        medicamentName_2_volume_2: "0",
        medicamentName_3_volume_1: "0",
        medicamentName_3_volume_9: "0",
        medicamentName_6_volume_13: "0",
        medicamentName_1_volume_7: "0",
        medicamentName_2_volume_15: "0",
        medicamentName_3_volume_7: "0",
        medicamentName_5_volume_4: "0",
        medicamentName_5_volume_11: "0",
        medicamentName_9_volume_2: "0",
        medicamentName_9_volume_5: "0",
        medicamentName_10_volume_1: "0",
        medicamentName_1_volume_12: "0",
        medicamentName_3_volume_6: "0",
        medicamentName_3_volume_13: "0",
        medicamentName_5_volume_14: "0",
        medicamentName_7_volume_4: "0",
        medicamentName_10_volume_8: "0",
        medicamentName_5_volume_6: "0",
        medicamentName_8: "",
        medicamentName_8_volume_11: "0",
        medicamentName_2_volume_6: "0",
        medicamentName_3_volume_4: "0",
        medicamentName_1_volume_9: "0",
        medicamentName_4_volume_8: "0",
        medicamentName_5_volume_10: "0",
        medicamentName_6_volume_11: "0",
        medicamentName_8_volume_3: "0",
        medicamentName_1_volume_8: "0",
        medicamentName_6_volume_12: "0",
        medicamentName_10_volume_2: "0",
        medicamentName_4_volume_6: "0",
        medicamentName_5_volume_1: "0",
        medicamentName_6_volume_10: "0",
        medicamentName_8_volume_1: "0",
        medicamentName_8_volume_8: "0",
        medicamentName_2_volume_9: "0",
        medicamentName_4_volume_4: "0",
        medicamentName_9_volume_4: "0",
        medicamentName_10_volume_13: "0",
        medicamentName_10_volume_15: "0",
        medicamentName_1_volume_2: "0",
        medicamentName_2_volume_14: "0",
        medicamentName_3_volume_5: "0",
        medicamentName_3_volume_12: "0",
        medicamentName_10_volume_6: "0",
        medicamentName_3_volume_10: "0",
        medicamentName_7_volume_15: "0",
        medicamentName_4_volume_13: "0",
        medicamentName_7_volume_12: "0",
        medicamentName_8_volume_5: "0",
        medicamentName_3_volume_15: "0",
        medicamentName_4_volume_15: "0",
        medicamentName_5_volume_9: "0",
        medicamentName_5_volume_13: "0",
        medicamentName_10_volume_4: "0",
        medicamentName_2_volume_3: "0",
        medicamentName_6_volume_9: "0",
        medicamentName_7_volume_11: "0",
        medicamentName_10: "",
        medicamentName_10_volume_11: "0",
        medicamentName_1_volume_15: "0",
        medicamentName_2_volume_1: "0",
        medicamentName_5: "",
        medicamentName_8_volume_2: "0",
        medicamentName_8_volume_9: "0",
        medicamentName_9_volume_15: "0",
        medicamentName_1_volume_13: "0",
        medicamentName_3_volume_11: "0",
        medicamentName_5_volume_2: "0",
        medicamentName_6_volume_1: "0",
        medicamentName_7_volume_7: "0",
        medicamentName_7_volume_2: "0",
        medicamentName_7_volume_3: "0",
        medicamentName_9_volume_12: "0",
        medicamentName_1: "消毒水",
        medicamentName_1_volume_10: "0",
        medicamentName_2_volume_12: "0",
        medicamentName_4_volume_1: "0",
        medicamentName_6_volume_14: "0",
        medicamentName_1_volume_3: "0",
        medicamentName_3_volume_2: "0",
        medicamentName_7_volume_13: "0",
        medicamentName_7_volume_14: "0",
        medicamentName_3_volume_8: "0",
        medicamentName_4: "",
        medicamentName_4_volume_3: "0",
        medicamentName_4_volume_12: "0",
        medicamentName_6_volume_2: "0",
        medicamentName_7_volume_9: "0",
        medicamentName_10_volume_12: "0",
        medicamentName_1_volume_4: "0",
        medicamentName_5_volume_7: "0",
        medicamentName_5_volume_12: "0",
        medicamentName_6_volume_4: "0",
        medicamentName_6_volume_8: "0",
        medicamentName_5_volume_8: "0",
        medicamentName_7: "",
        medicamentName_1_volume_11: "0",
        medicamentName_4_volume_5: "0",
        medicamentName_8_volume_10: "0",
        medicamentName_8_volume_13: "0",
        medicamentName_1_volume_1: "0",
        medicamentName_4_volume_9: "0",
        medicamentName_5_volume_5: "0",
        medicamentName_8_volume_12: "0",
        medicamentName_2_volume_11: "0",
        medicamentName_3: "",
        medicamentName_8_volume_4: "0",
        medicamentName_9_volume_3: "0",
      },
      pump: {
        // 泵校准
        medicament_1_capacity: "",
        medicament_2_capacity: "",
        medicament_3_capacity: "",
        medicament_4_capacity: "",
        medicament_5_capacity: "",
        medicament_6_capacity: "",
        medicament_7_capacity: "",
        medicament_8_capacity: "",
        medicament_9_capacity: "",
        medicament_10_capacity: "",
        medicament_1_time: "",
        medicament_2_time: "",
        medicament_3_time: "",
        medicament_4_time: "",
        medicament_5_time: "",
        medicament_6_time: "",
        medicament_7_time: "",
        medicament_8_time: "",
        medicament_9_time: "",
        medicament_10_time: "",
        medicament_1_correction: "",
        medicament_2_correction: "",
        medicament_3_correction: "",
        medicament_4_correction: "",
        medicament_5_correction: "",
        medicament_6_correction: "",
        medicament_7_correction: "",
        medicament_8_correction: "",
        medicament_9_correction: "",
        medicament_10_correction: "",
      },
      price: {
        medicament_1_price: 0,
        medicament_2_price: 0,
        medicament_3_price: 0,
        medicament_4_price: 0,
        medicament_5_price: 0,
        medicament_6_price: 0,
        medicament_7_price: 0,
        medicament_8_price: 0,
        medicament_9_price: 0,
        medicament_10_price: 0,
      },
      stat: {},
      timer_status: 0, // 状态计时器状态
    };
  },
  methods: {
    // 获取模板详情
    async getTemplateDetail() {
      let res = await this.$http.post("template/detail", {
        id: this.$route.query.id,
      });
      if (res.data.code !== 2000) {
        this.$router.go(-1);
        return;
      }
      // 基本设定
      if (res?.data?.data?.first) {
        this.system = res.data.data.first;
      }

      // 配方设定

      if (res?.data?.data?.second) {
        let second = res.data.data.second;
        let list = [];
        let obj = {};
        for (let i = 1; i < 16; i++) {
          obj = {};
          obj.name = second["recipe_name_" + i];
          for (let j = 1; j < 11; j++) {
            obj[j] = second["medicamentName_" + j + "_volume_" + i];
          }
          list[i - 1] = obj;
        }
        obj = {};
        for (let i = 1; i < 11; i++) {
          obj[i] = second["medicamentName_" + i];
        }
        obj.name = `配方名称\\药剂名称`;
        list.unshift(obj);
        this.tableData = list;
        // 配方设定2
        this.rinse = res.data.data.second;
      }

      // 化料单价设定
      if (res?.data?.data?.third) {
        this.price = res.data.data.third;
      }
      // 泵校准
      if (res?.data?.data?.fourth) {
        this.pump = res.data.data.fourth;
      }
      // 首次加料
      if (res?.data?.data?.fifth) {
        this.first_feed = res.data.data.fifth;
      }
      // 药剂系数修正
      if (res?.data?.data?.sixth) {
        this.reagent = res.data.data.sixth;
      }
      console.log(res.data);
    },
    // 保存模板详情
    editSave() {
      for (let i = 1; i < 16; i++) {
        this.rinse["recipe_name_" + i] = this.tableData[i].name;
        for (let j = 1; j < 11; j++) {
          this.rinse["medicamentName_" + j + "_volume_" + i] = this.tableData[
            i
          ][j];
        }
      }
      for (let i = 1; i < 11; i++) {
        this.rinse["medicamentName_" + i] = this.tableData[0][i];
      }

      let data = {
        id: this.$route.query.id,
        first: this.system,
        second: this.rinse,
        third: this.price,
        fourth: this.pump,
        fifth: this.first_feed,
        sixth: this.reagent,
      };

      this.$confirm(
        this.fun.getLanguageText(
          this.$store.state.language,
          "是否确认修改模板？"
        ),
        this.fun.getLanguageText(this.$store.state.language, "提示"),
        {
          confirmButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "确定"
          ),
          cancelButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "取消"
          ),
          type: "warning",
        }
      )
        .then(async () => {
          let res = await this.$http.post("template/edit-save", data);
          if (res.data.code !== 2000) {
            this.$message.error(
              this.fun.getLanguageText(this.$store.state.language, res.data.msg)
            );
            return;
          }
          this.$message({
            type: "success",
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "修改模板成功"
            ),
          });
          await this.getTemplateDetail();
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "已取消删除"
            ),
          });
        });
    },
    getListHeight() {
      let Html = document.getElementsByClassName("box-card");
      if (Html.length > 0) {
        this.$store.state.clientHeight = Html[0].clientHeight - 131 - 80;
      }
    },
  },
  mounted() {
    this.getListHeight();
    window.onresize = () => {
      return (() => {
        this.getListHeight();
      })();
    };
  },
  destroyed() {
    window.onresize = null;
  },
  created() {
    this.getTemplateDetail();
  },
};
</script>
<style lang="less">
.el-table thead.is-group th {
  background: #ffffff;
}

.set1 thead {
  tr:nth-child(2) {
    th {
      display: none;
    }
  }
}
</style>
<style lang="less" scoped>
.showImg {
  // width: 100%;
  // height: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.el-form-item {
  width: 46%;

  .el-input {
    width: 200px;
  }

  .el-button {
    width: 200px;
    margin-right: 90px;
  }
}

.el-input-none {
  width: 200px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  font-size: 20px;
  margin-bottom: 14px;
}

.el-form-four {
  .el-form-item {
    // width: 300px;
    // margin-right: 20px;
  }

  .el-select {
    width: 400px;
  }
}

.debug {
  .el-button {
    width: 100px;
    margin-right: 0;
  }

  .el-form-item {
    width: 280px;
  }
}
</style>
